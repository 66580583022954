import { APIPurchaseInvoiceAttachment } from '../../types/api';
import { ID } from '../../types/general';

import {
  ExtractActionTypes,
  makeApiActions,
  makeAction,
} from '../../utils/actionCreators';
import {
  BackendError,
  GET,
  apiErrorHandlingWithDecode,
  getFileWithCredentials,
} from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import { getInvoiceAttachmentFileRequest } from '../reducers/invoiceAttachments';

export type InvoiceAttachmentsAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeApiActions('get', 'invoiceAttachments')<
    APIPurchaseInvoiceAttachment[]
  >(),
  ...makeAction('getInvoiceAttachmentFileStarted')<{
    invoiceHeaderId: string;
    url: string;
  }>(),
  ...makeAction('getInvoiceAttachmentFileFailure')<{
    invoiceHeaderId: string;
    url: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getInvoiceAttachmentFileSuccess')<{
    invoiceHeaderId: string;
    url: string;
    localImageUrl: string;
    popUpBlocked?: boolean;
  }>(),
};
export const {
  getInvoiceAttachmentsStarted,
  getInvoiceAttachmentsSuccess,
  getInvoiceAttachmentsFailure,
  getInvoiceAttachmentFileStarted,
  getInvoiceAttachmentFileFailure,
  getInvoiceAttachmentFileSuccess,
} = actionCreators;

const fetchInvoiceAttachmentsForOrder = (orderId: ID) => {
  return GET<APIPurchaseInvoiceAttachment[]>(
    `v1/orders/${orderId}/purchase-invoice-attachments`
  );
};

export const requestInvoiceAttachmentsForOrder = (orderId: ID): Thunk => (
  dispatch
) => {
  dispatch(getInvoiceAttachmentsStarted());
  fetchInvoiceAttachmentsForOrder(orderId).then(
    (response) => {
      dispatch(getInvoiceAttachmentsSuccess(response));
    },
    (error) => {
      dispatch(getInvoiceAttachmentsFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};

export const fetchInvoiceAttachmentFile = (
  invoiceHeaderId: ID,
  url: string,
  openInNewTab?: boolean
) =>
  createAsyncThunk(getFileWithCredentials, {
    args: [url],
    isPending: flow(
      getInvoiceAttachmentFileRequest(invoiceHeaderId, url),
      remoteData.isLoading
    ),
    initialAction: getInvoiceAttachmentFileStarted({ invoiceHeaderId, url }),
    successActionCreator: (localImageUrl) => {
      let popUpBlocked = false;

      if (openInNewTab) {
        const openSuccess = window.open(localImageUrl, '_blank');

        if (openSuccess === null) {
          popUpBlocked = true;
        }
      }

      return getInvoiceAttachmentFileSuccess({
        invoiceHeaderId,
        url,
        localImageUrl,
        popUpBlocked,
      });
    },
    failureActionCreator: (error) =>
      getInvoiceAttachmentFileFailure({
        invoiceHeaderId,
        url,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
