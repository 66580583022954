import { APIActualCostAttachment } from '../../types/api';
import { ID } from '../../types/general';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  BackendError,
  getFileWithCredentials,
} from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import {
  getActualCostAttachmentRequest,
  getActualCostAttachmentFileRequest,
} from '../reducers/actualCostAttachments';

export type ActualCostAttachmentsAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeAction('getActualCostAttachmentsStarted')<{ orderId: string }>(),
  ...makeAction('getActualCostAttachmentsFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getActualCostAttachmentsSuccess')<{
    orderId: string;
    actualCostAttachments: APIActualCostAttachment[];
  }>(),
  ...makeAction('getActualCostAttachmentFileStarted')<{
    actualCostId: string;
    url: string;
  }>(),
  ...makeAction('getActualCostAttachmentFileFailure')<{
    actualCostId: string;
    url: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getActualCostAttachmentFileSuccess')<{
    actualCostId: string;
    url: string;
    localImageUrl: string;
    popUpBlocked?: boolean;
  }>(),
};

export const {
  getActualCostAttachmentsStarted,
  getActualCostAttachmentsSuccess,
  getActualCostAttachmentsFailure,
  getActualCostAttachmentFileStarted,
  getActualCostAttachmentFileSuccess,
  getActualCostAttachmentFileFailure,
} = actionCreators;

const fetchActualCostAttachmentsForOrder = (orderId: ID) => {
  return GET<APIActualCostAttachment[]>(
    `v1/orders/${orderId}/actual-costs-attachments`
  );
};

export const requestActualCostAttachmentsForOrder = (orderId: ID): Thunk => (
  dispatch
) => {
  dispatch(
    createAsyncThunk(fetchActualCostAttachmentsForOrder, {
      args: [orderId],
      isPending: flow(
        getActualCostAttachmentRequest(orderId),
        remoteData.isLoading
      ),
      initialAction: getActualCostAttachmentsStarted({ orderId }),
      successActionCreator: (actualCostAttachments) =>
        getActualCostAttachmentsSuccess({ orderId, actualCostAttachments }),
      failureActionCreator: (error) =>
        getActualCostAttachmentsFailure({
          orderId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

export const fetchActualCostAttachmentFile = (
  actualCostId: ID,
  url: string,
  openInNewTab?: boolean
) =>
  createAsyncThunk(getFileWithCredentials, {
    args: [url],
    isPending: flow(
      getActualCostAttachmentFileRequest(actualCostId, url),
      remoteData.isLoading
    ),
    initialAction: getActualCostAttachmentFileStarted({ actualCostId, url }),
    successActionCreator: (localImageUrl) => {
      let popUpBlocked = false;

      if (openInNewTab) {
        const openSuccess = window.open(localImageUrl, '_blank');

        if (openSuccess === null) {
          popUpBlocked = true;
        }
      }

      return getActualCostAttachmentFileSuccess({
        actualCostId,
        url,
        localImageUrl,
        popUpBlocked,
      });
    },
    failureActionCreator: (error) =>
      getActualCostAttachmentFileFailure({
        actualCostId,
        url,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
