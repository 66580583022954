import { useSelector } from 'react-redux';

import { Reducer } from 'redux';

import { ID } from '@customtypes/general';

import { APIPurchaseInvoiceAttachment } from '../../types/api';

import { BackendError, getBaseApiUrl } from '../../utils/api';
import { updateRecord } from '../../utils/general';
import * as remoteData from '../../utils/remoteData';
import { Selector } from './utils';

import { ActionTypes } from '../actionTypes';
import { getInvoiceHeaderById } from './invoiceHeader';

import { AppState } from '.';

type InvoiceAttachmentDataState = Record<ID, APIPurchaseInvoiceAttachment>;

type InvoiceAttachmentFileState = Partial<
  Record<
    string,
    Record<string, remoteData.RemoteData<string, BackendError | undefined>>
  >
>;

type InvoiceAttachmentState = {
  data: InvoiceAttachmentDataState;
  files: InvoiceAttachmentFileState;
};

const initialState: InvoiceAttachmentState = { data: {}, files: {} };

const invoiceAttachmentsReducer: Reducer<
  InvoiceAttachmentState,
  ActionTypes
> = (state = initialState, action): InvoiceAttachmentState => {
  switch (action.type) {
    case 'GET_INVOICE_ATTACHMENTS_SUCCESS':
      return { ...state, data: updateRecord(state.data, action.payload) };
    case 'GET_INVOICE_ATTACHMENT_FILE_STARTED': {
      const { url, invoiceHeaderId } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          [invoiceHeaderId]: {
            ...state.files[invoiceHeaderId],
            [url]: remoteData.loading,
          },
        },
      };
    }
    case 'GET_INVOICE_ATTACHMENT_FILE_FAILURE': {
      const { url, error, invoiceHeaderId } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          [invoiceHeaderId]: {
            ...state.files[invoiceHeaderId],
            [url]: remoteData.fail(error),
          },
        },
      };
    }
    case 'GET_INVOICE_ATTACHMENT_FILE_SUCCESS': {
      const { url, localImageUrl, invoiceHeaderId } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          [invoiceHeaderId]: {
            ...state.files[invoiceHeaderId],
            [url]: remoteData.succeed(localImageUrl),
          },
        },
      };
    }

    default:
      return state;
  }
};

export const useInvoiceHeaderAttachmentUrls = (purchaseInvoiceHeaderId: ID) => {
  return useSelector(
    (state: AppState) => {
      const invoiceHeader = getInvoiceHeaderById(purchaseInvoiceHeaderId)(
        state
      );

      if (!invoiceHeader) {
        return [];
      }

      return [
        ...(invoiceHeader.invoiceLink ? [invoiceHeader.invoiceLink] : []),
        ...Object.values(state.invoiceAttachments.data)
          .filter((row) =>
            invoiceHeader.purchaseInvoiceAttachmentIds.includes(row.id)
          )
          .map((row) => row.attachmentLink),
        ...Object.values(state.invoiceAttachmentFiles.invoiceAttachments)
          .filter((row) => row.purchaseInvoiceHeaderId === invoiceHeader.id)
          .map(
            (row) =>
              `${getBaseApiUrl()}v1/attachments/purchase-invoice-headers/${
                row.purchaseInvoiceHeaderId
              }/files/${row.id}`
          ),
      ];
    },
    (a, b) => a.join() === b.join()
  );
};

export function isAnyInvoiceAttachmentRequestsPending(
  invoiceHeaderId: string
): Selector<boolean> {
  return ({
    invoiceAttachments: {
      files: { [invoiceHeaderId]: invoiceRequests },
    },
  }) => {
    const requests = invoiceRequests ? Object.values(invoiceRequests) : [];

    return requests.some(remoteData.isLoading);
  };
}

export function getInvoiceAttachmentFileRequest(
  invoiceHeaderId: string,
  url: string
): Selector<remoteData.RemoteData<string>> {
  return ({
    invoiceAttachments: {
      files: { [invoiceHeaderId]: invoiceRequests },
    },
  }) => {
    const request = invoiceRequests?.[url];

    return request ?? remoteData.notAsked;
  };
}
export default invoiceAttachmentsReducer;
