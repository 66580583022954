import { Reducer } from 'redux';

import * as api from '../../utils/api';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';

import { AppState } from '.';

type Err = api.BackendError | undefined;

export type PurchaseInvoiceReclamation = {
  id: number;
  purchaseInvoiceHeaderId: number;
  receiverEmail: string;
  ccEmail: string | null;
  responseEmail: string | null;
  reason: string | null;
  emailTitle: string;
  emailMessage: string;
  hasAttachments: boolean;
  isSentSuccessfully: boolean;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type PurchaseInvoiceReclamationState = {
  requests: Partial<
    Record<string, remoteData.RemoteData<undefined | string, Err>>
  >;
  data: Record<string, PurchaseInvoiceReclamation>;
};

const initialState: PurchaseInvoiceReclamationState = {
  requests: {},
  data: {},
};

const purchaseInvoiceReclamationReducer: Reducer<
  PurchaseInvoiceReclamationState,
  ActionTypes
> = (state = initialState, action): PurchaseInvoiceReclamationState => {
  switch (action.type) {
    case 'PUT_INVOICE_HEADER_COMPLAINT_STARTED': {
      const { purchaseInvoiceHeaderId } = action.payload;

      return {
        ...state,
        requests: {
          ...state.requests,
          [purchaseInvoiceHeaderId]: remoteData.loading,
        },
      };
    }
    case 'PUT_INVOICE_HEADER_COMPLAINT_SUCCESS': {
      const {
        purchaseInvoiceReclamations = [],
        purchaseInvoiceHeaderId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          ...normalizeBy(
            'purchaseInvoiceHeaderId',
            purchaseInvoiceReclamations
          ),
        },
        requests: {
          ...state.requests,
          [purchaseInvoiceHeaderId]: remoteData.succeed<string>('success'),
        },
      };
    }
    case 'PUT_INVOICE_HEADER_COMPLAINT_FAILURE': {
      const { purchaseInvoiceHeaderId, error } = action.payload;

      return {
        ...state,
        requests: {
          ...state.requests,
          [purchaseInvoiceHeaderId]: remoteData.fail(error),
        },
      };
    }
    case 'PUT_CANCEL_INVOICE_HEADER_COMPLAINT_SUCCESS': {
      const { purchaseInvoiceHeaderId } = action.payload;

      return {
        ...state,
        requests: {
          ...state.requests,
          [purchaseInvoiceHeaderId]: remoteData.notAsked,
        },
      };
    }
  }

  return state;
};

export const isSentSuccessfully = (purchaseInvoiceHeaderId: string) => ({
  purchaseInvoiceReclamations: {
    requests: { [purchaseInvoiceHeaderId]: requestState = remoteData.notAsked },
  },
}: AppState) => requestState.kind === 'Success';

export const isLoading = (purchaseInvoiceHeaderId: string) => ({
  purchaseInvoiceReclamations: {
    requests: { [purchaseInvoiceHeaderId]: requestState = remoteData.notAsked },
  },
}: AppState) => requestState.kind === 'Loading';

export default purchaseInvoiceReclamationReducer;
