import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { getActualCostImageRequest } from '../../store/reducers/actualCostAttachmentFiles';
import {
  getActualCostAttachmentFileRequest,
  useActualCostAttachmentUrls,
} from '../../store/reducers/actualCostAttachments';
import { getInvoiceHeaderImageRequest } from '../../store/reducers/invoiceAttachmentFiles';
import {
  getInvoiceAttachmentFileRequest,
  useInvoiceHeaderAttachmentUrls,
} from '../../store/reducers/invoiceAttachments';
import { getOuterBarState } from '../../store/reducers/ui';

import {
  fetchActualCostAttachmentFile,
  fetchActualCostImageFile,
  fetchInvoiceAttachmentFile,
  fetchInvoiceImageFile,
} from '../../store/actions';
import { setOuterBarState } from '../../store/actions/ui';

import useRemoteData from '../../hooks/useRemoteData';
import useTxt from '../../hooks/useTxt';

import { isLoading } from '../../utils/remoteData';

import {
  IconCloseWhite,
  IconArrowRight,
  IconArrowLeft,
} from '../../assets/svg';

import { AppContext } from '../../context';
import { OrderParams, routes } from '../../routes';
import ActualCostLinesTable from '../../views/OrderView/components/ActualCostLines';
import InvoiceLinesTable from '../../views/OrderView/components/PurchaseInvoiceLines';
import { IconButton } from '../Buttons';
import { Spinner } from '../Loading';
import ToolTip from '../Tooltip';
import Txt from '../Txt';

const Content = styled.div`
  margin-left: ${(props) => props.theme.margin[4]};

  border-left: ${(props) => props.theme.color.black} 3px solid;

  padding-left: ${(props) => props.theme.margin[4]};

  width: 40vw;
  height: 60%;

  display: flex;
  flex-direction: column;

  flex-grow: 1;

  background: ${(props) => props.theme.color.embedBackground};
`;

const TableContainer = styled.div`
  margin-left: ${(props) => props.theme.margin[4]};

  border-top: 3px solid ${({ theme }) => theme.color.rowBorder};
  border-left: ${(props) => props.theme.color.black} 3px solid;

  padding-left: ${(props) => props.theme.margin[4]};

  width: 40vw;
  height: 40%;

  display: flex;
  flex-direction: column;

  flex-grow: 1;

  overflow-y: scroll;
`;

const PaddedIconButton = styled(IconButton)`
  margin: ${(props) => props.theme.margin[8]};

  border-radius: ${(props) => props.theme.margin[4]};

  width: ${(props) => props.theme.margin[40]};
  height: ${(props) => props.theme.margin[40]};

  display: flex;
  justify-content: center;

  background-color: ${(props) => props.theme.color.black};
`;

type OuterBarProps = PropsWithChildren<{
  onClose: () => void;
}>;

const OuterBar = ({ onClose }: OuterBarProps) => {
  const outerBarState = useSelector(getOuterBarState());

  const location = useLocation();

  const pathMatchesOrderPaths = matchPath<OrderParams>(location.pathname, {
    path: [routes.ORDER],
    exact: true,
  });

  const projectId = pathMatchesOrderPaths?.params.projectId ?? null;
  const orderId = pathMatchesOrderPaths?.params.orderId ?? null;

  const selector = () => {
    if (
      outerBarState?.type === 'invoice_header' &&
      outerBarState?.attachmentUrl
    ) {
      return getInvoiceAttachmentFileRequest(
        outerBarState.contentId,
        outerBarState?.attachmentUrl
      );
    }

    if (outerBarState?.type === 'invoice_header') {
      return getInvoiceHeaderImageRequest(outerBarState.contentId);
    }

    if (outerBarState?.type === 'actual_cost' && outerBarState?.attachmentUrl) {
      return getActualCostAttachmentFileRequest(
        outerBarState.contentId,
        outerBarState?.attachmentUrl
      );
    }

    return getActualCostImageRequest(outerBarState?.contentId ?? '');
  };

  const fetchData = () => {
    if (
      outerBarState?.type === 'invoice_header' &&
      outerBarState?.attachmentUrl
    ) {
      return fetchInvoiceAttachmentFile(
        outerBarState?.contentId,
        outerBarState?.attachmentUrl
      );
    }

    if (outerBarState?.type === 'invoice_header') {
      return fetchInvoiceImageFile(outerBarState?.contentId);
    }

    if (outerBarState?.type === 'actual_cost' && outerBarState?.attachmentUrl) {
      return fetchActualCostAttachmentFile(
        outerBarState?.contentId ?? '',
        outerBarState?.attachmentUrl
      );
    }

    return fetchActualCostImageFile(outerBarState?.contentId ?? '');
  };

  const iframeContent = useRemoteData(selector(), fetchData(), !!outerBarState);

  const showSpinner = isLoading(useSelector(selector()));

  if (!outerBarState) {
    return null;
  }

  const linesTable = () => {
    if (outerBarState?.type === 'invoice_header') {
      return (
        <InvoiceLinesTable
          invoiceHeaderId={outerBarState?.contentId}
          projectId={projectId}
          orderId={orderId}
        />
      );
    }

    return (
      <ActualCostLinesTable
        actualCostId={outerBarState?.contentId}
        projectId={projectId}
        orderId={orderId}
      />
    );
  };

  return (
    <AppContext.Consumer>
      {(context) => {
        return (
          context.outerBarContainer &&
          ReactDOM.createPortal(
            <>
              <Content>
                <Row>
                  <PaddedIconButton onClick={onClose} icon={IconCloseWhite} />
                  <InvoiceAttachmentSelector
                    contentId={outerBarState.contentId}
                    outerBarStateType={outerBarState.type}
                    attachmentUrl={outerBarState.attachmentUrl}
                  />
                  <div />
                </Row>
                {showSpinner ? (
                  <StyledSpinner size="4rem" light />
                ) : (
                  <>
                    <Embed
                      key={iframeContent}
                      src={iframeContent}
                      type="application/pdf"
                    />
                    <StyledH2>
                      <Txt id="order.invoice.no.image" />
                    </StyledH2>
                  </>
                )}
              </Content>
              <TableContainer>{linesTable()}</TableContainer>
            </>,
            context.outerBarContainer
          )
        );
      }}
    </AppContext.Consumer>
  );
};

const Embed = styled.embed`
  height: 30vw;
  z-index: 20;
`;

const StyledH2 = styled.h2`
  position: absolute;
  top: 100px;

  width: 300px;

  font-weight: bold;
  color: white;
  text-align: center;

  z-index: 10;
`;

const StyledSpinner = styled(Spinner)`
  margin-top: ${(props) => props.theme.margin[128]};

  display: flex;
  align-items: center;
  justify-content: center;

  align-self: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InvoiceAttachmentSelector = ({
  contentId,
  outerBarStateType,
  attachmentUrl,
}: {
  contentId: string;
  outerBarStateType: 'invoice_header' | 'actual_cost';
  attachmentUrl?: string;
}) => {
  const [state, setState] = React.useState({
    contentId,
    outerBarStateType,
    changedIndex: 0,
  });

  // index 0 is always the invoice/actual_cost image pdf
  const [index, setIndex] = React.useState<number>(0);

  const dispatch = useDispatch();

  const selectorTip = useTxt('order.invoice.attachment.selector.tooltip');

  const invoiceAttachmentUrls = useInvoiceHeaderAttachmentUrls(
    outerBarStateType === 'invoice_header' ? contentId : ''
  );

  const actualCostAttachmentUrls = useActualCostAttachmentUrls(
    outerBarStateType === 'actual_cost' ? contentId : ''
  );

  const attachmentUrls =
    outerBarStateType === 'invoice_header'
      ? invoiceAttachmentUrls
      : actualCostAttachmentUrls;

  // if state is changed externally, reset index
  useEffect(() => {
    if (
      contentId !== state.contentId ||
      outerBarStateType !== state.outerBarStateType
    ) {
      setIndex(0);
      setState({ contentId, outerBarStateType, changedIndex: 0 });
    }
  }, [contentId, outerBarStateType, state]);

  // if index is changed, update outer bar state
  useEffect(() => {
    if (attachmentUrls.length === 0) {
      return;
    }

    if (
      index > 0 &&
      attachmentUrls[index - 1] !== attachmentUrl &&
      state.changedIndex !== index
    ) {
      dispatch(
        setOuterBarState({
          type: outerBarStateType,
          contentId,
          attachmentUrl: attachmentUrls[index - 1],
        })
      );

      setState({ contentId, outerBarStateType, changedIndex: index });
    }

    if (
      index === 0 &&
      attachmentUrl !== undefined &&
      state.changedIndex !== index
    ) {
      dispatch(
        setOuterBarState({
          type: outerBarStateType,
          contentId,
        })
      );
      setState({ contentId, outerBarStateType, changedIndex: index });
    }
  }, [
    index,
    dispatch,
    attachmentUrls,
    contentId,
    outerBarStateType,
    attachmentUrl,
    state.changedIndex,
  ]);

  const onLeftButtonClick = () => {
    setIndex((prev) => prev - 1);
  };

  const onRightButtonClick = () => {
    setIndex((prev) => prev + 1);
  };

  return (
    <ButtonRow>
      <StyledIconButton
        icon={index === 0 ? EmptySvg : IconArrowLeft}
        disabled={index === 0}
        onClick={onLeftButtonClick}
      />
      <ToolTip tip={selectorTip}>{`${index + 1} / ${
        attachmentUrls.length + 1
      }`}</ToolTip>
      <StyledIconButton
        icon={index === attachmentUrls.length ? EmptySvg : IconArrowRight}
        disabled={index === attachmentUrls.length}
        onClick={onRightButtonClick}
      />
    </ButtonRow>
  );
};

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  color: white;
  gap: 1rem;
`;

const StyledIconButton = styled(IconButton)`
  fill: white;
`;

const EmptySvg = styled.svg`
  width: 16px;
  height: 16px;
`;

export default OuterBar;
